<script lang="js">
import { Fireworks } from '@fireworks-js/vue'
import rawBestOf from './best-of-94.json'
import rawHappy from './happy-new-year-83.json'
import rawClassics from './karaoke-classics-68.json'
import rawRock from './rock-legenden-4.json'
import rawFavs from './favs.json'
import {computed, nextTick, onMounted, ref, watch} from "vue";

export default {
  name: 'App',
  components: {
    Fireworks
  },
  setup() {
    const rawData = {
      bestof: rawBestOf,
      happy: rawHappy,
      classics: rawClassics,
      rock: rawRock,
      favs: rawFavs
    }

    const sortByObjectKey = (unordered) => {
      return Object.keys(unordered).sort().reduce(
          (obj, key) => {
            obj[key] = unordered[key];
            return obj;
          },
          {}
      );
    }

    const data = {
      bestof: {
        title: 'Best of Karaoke',
        songs: sortByObjectKey(rawData.bestof)
      },
      favs: {
        title: 'Andi\'s Favoriten',
        songs: sortByObjectKey(rawData.favs)
      },
      classics: {
        title: 'Karaoke Classics',
        songs: sortByObjectKey(rawData.classics)
      },
      rock: {
        title: 'Rock Legenden',
        songs: sortByObjectKey(rawData.rock)
      }
    }

    const currentSet = ref('favs')
    const currentPage = ref(1)
    const songsPerPage = 7 * 5

    const maxPages = computed(() => {
      return Math.ceil(Object.keys(data[currentSet.value].songs).length / songsPerPage)
    })

    const currentTitle = computed(() => {
      return data[currentSet.value].title
    })

    const currentSongs = computed(() => {
      return getSongsPerPage(currentPage.value)
    })

    function getSongsPerPage(page) {
      const songs = data[currentSet.value].songs
      const start = (page - 1) * songsPerPage
      const end = page * songsPerPage
      return Object.keys(songs).slice(start, end).map(key => {
        return {
          artist: key,
          songs: songs[key]
        }
      })
    }

    const pagesTotal = 18
    const timeTotal = 5 * 60
    // const timePerPage = 1 * 1000
    // const timePerPage = 10000000
    const timePerPage = (timeTotal / pagesTotal) * 1000
    const currentTimer = ref(0)
    onMounted(() => {
      setInterval(() => {
        updateTime()
        containerHeight.value = window.document.body.clientHeight - containerElement.value?.clientHeight - 87

        currentTimer.value += 10
        if (currentTimer.value >= timePerPage) {
          next()
        }
      }, 10)
    })

    const timerPercentage = computed(() => {
      return (currentTimer.value / timePerPage) * 100
    })

    const containerHeight = ref(0)
    const containerElement = ref(null)

    const urlParams = new URLSearchParams(window.location.search);
    const targetTime = new Date()
    targetTime.setHours(urlParams.get('h') ?? 23)
    targetTime.setMinutes(urlParams.get('m') ?? 59)
    targetTime.setSeconds(59)
    targetTime.setMilliseconds(999)

    const debug = ref(urlParams.has('debug'))

    const timeLeft = ref({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    })

    function updateTime() {
      const now = new Date()
      now.setHours(now.getHours() + 10)
      date.value = now
      const diff = targetTime.getTime() - now.getTime()
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((diff % (1000 * 60)) / 1000)
      timeLeft.value = {
        hours,
        minutes,
        seconds
      }
    }

    const date = ref();

    function next() {
      smallMessage.value = randomSmallMessages[Math.floor(Math.random() * randomSmallMessages.length)]
      bigMessage.value = randomBigMessages[Math.floor(Math.random() * randomBigMessages.length)]

      currentTimer.value = 0
      currentPage.value++
      if (getSongsPerPage(currentPage.value).length < 5) {
        currentPage.value++
      }

      if (currentPage.value > maxPages.value) {
        if (currentSet.value === 'bestof') {
          currentSet.value = 'favs'
        } else if (currentSet.value === 'favs') {
          currentSet.value = 'classics'
        } else if (currentSet.value === 'classics') {
          currentSet.value = 'rock'
        } else if (currentSet.value === 'rock') {
          currentSet.value = 'bestof'
        }
        currentPage.value = 1
      }
    }

    const randomSmallMessages = [
        '... und noch 50\'000 weitere Songs!',
        '... und noch 50\'000 weitere Songs!',
        '... und noch 50\'000 weitere Songs!',
        'Trousch jo ned!',
        'Wer esch eigentlich de Kara-Oke?',
        'Sing deinen Lieblingssong!',
        'Sing mir mir!',
    ]
    const randomBigMessages = [
        'Someone took a photo of me singing REM songs at karaoke.<br>That’s me in the corner.',
        'Agreed to do karaoke with some friends recently and found out the bar only had disco classics.<br>At first I was afraid. I was petrified.',
        'At any karaoke, the urge to sing The Lion Sleeps Tonight is never more than a whim away.',
        'My girlfriend left me because of my obsession with Linkin Park.<br>But in the end, it doesn’t even matter.',
        'What concert costs 45 cents?<br>50 Cent featuring Nickelback.',
        'I was just about to make a joke about a \'Freak on a Leash\',<br>but then I decided that it will be too Korn-y.',
        'My wife demanded I stop quoting Oasis lyrics or she\'ll have to leave me<br>I said maybe',
        'I absolutely love the lyrics to the song "What is Love?"<br>The artist really haddaway with words...',
        'My friend told me he felt sad because he didn\'t know the lyrics to "YMCA".<br>I said "Young man, there\'s no need to feel down".',
        ...randomSmallMessages
    ]
    const smallMessage = ref('')
    const bigMessage = ref('')

    const showTimer = computed(() => {
      return false;
      // return timeLeft.value.hours === 0 && (
      //     timeLeft.value.minutes <= 10
      // )
    })
    const showNewYear = computed(() => {
      return false;
      // return timeLeft.value.hours === -1 && timeLeft.value.minutes > -5
    })

    function formatNumber(number) {
      return number.toString().padStart(2, '0')
    }

    const fw = ref()
    const options = ref({ opacity: 1 })
    async function startFireworks() {
      if (!fw.value) return
      fw.value.start()
      await new Promise((resolve) => setTimeout(resolve, 1000))
      await fw.value.waitStop()
    }
    // watch(fw, () => startFireworks())

    return {
      data,
      currentSet,
      currentPage,
      currentSongs,
      timerPercentage,
      currentTitle,
      maxPages,
      containerElement,
      containerHeight,
      timeLeft,
      next,
      smallMessage,
      debug,
      showTimer,
      showNewYear,
      formatNumber,
      options,
      bigMessage,
      date
    }
  }
}
</script>

<template>
  <Fireworks
      ref="fw"
      v-if="showNewYear"
      :autostart="true"
      :options="options"
      :style="{
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      position: 'fixed',
      background: '#000'
    }"
  />

  <template v-if="showTimer">
    <div class="timer">
      <div class="timer__time" v-if="timeLeft.minutes >= 0">
        <div v-if="timeLeft.minutes == 0 && timeLeft.seconds <= 10" :style="'font-size: ' + (20 + ((10 - timeLeft.seconds)*5)) + 'em;'">
          {{ timeLeft.seconds }}
        </div>
        <div v-else style="font-size: 20em;">
          <span class="timer__time__minutes">{{ formatNumber(timeLeft.minutes) }}:{{ formatNumber(timeLeft.seconds) }}</span>
        </div>
      </div>
    </div>
  </template>
  <template v-else-if="showNewYear">
    <div class="timer" style="font-size: 12em;">
        Happy New Year!
    </div>
  </template>
  <template v-else>
    <h1>{{ currentTitle }}
      <template v-if="debug">
        // {{ timeLeft.hours }}:{{ timeLeft.minutes }}:{{ timeLeft.seconds }} // {{ containerHeight }} // {{ currentPage }} / {{ maxPages }}
      </template>
    </h1>
    <div class="loader" :style="'width: ' + timerPercentage + '%'"></div>
    <div class="container" ref="containerElement" @click="next">
      <div v-for="song in currentSongs" :key="song.title" class="artist_container">
        <p class="artist" v-html="song.artist"></p>

        <p v-for="title in song.songs" :key="title" class="song" v-html="title">
        </p>
      </div>
    </div>
    <div class="message" :style="'height: ' + (containerHeight - 20) + 'px;'">
      <div class="big" v-if="containerHeight > 320" v-html="bigMessage"></div>
      <div class="normal" v-else-if="containerHeight > 220">
        {{ smallMessage }}
      </div>
      <div class="small" v-else-if="containerHeight > 120">
        {{ smallMessage }}
      </div>
    </div>
    <div class="powered">
      powered by karafun
      <template v-if="debug">
        {{ date?.toLocaleString() }}
        // {{ timeLeft.hours }}:{{ timeLeft.minutes }}:{{ timeLeft.seconds }}
      </template>
    </div>
  </template>
</template>

<style lang="scss">
html, body, #app {
  height: 100%;
}
* {
  font-family: Arial;
  padding: 0;
  margin: 0;
}
body {
  background-color: black;
  padding: 10px;
  overflow: hidden;
  position: relative;
}
.powered {
  position: absolute;
  bottom: 20px;
  right: 5px;
  color: white;
  font-size: 20px;
  padding: 5px;
}
h1 {
  margin: 20px 0 10px 0;
}
.timer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}
.message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .verybig {
    font-size: 6em;
  }
  .big {
    font-size: 4em;
    font-style: italic;
    //display: flex;
    //justify-content: space-between;
    //width: 100%;
    padding: 0 200px;
    text-align: center;
  }
  .normal {
    font-size: 4em;
  }
  .small {
    font-size: 3em;
  }
}
div.loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 7px;
  background-color: #005300;
}

#app {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div.container {
    width: 100%;
    font-size: 1.1em;
    //display: flex;
    //flex-direction: row;
    //justify-content: center;
    //align-content: center;
    //flex-wrap: wrap;
    column-count: 5;

    div.artist_container {
      break-inside: avoid-column;
      min-width: 30%;
      padding: 10px;
    }
  }
}

p.artist {
  margin-top: 1em;
  margin-bottom: 0.5em;
  background-color: #005300;
  padding: 5px;
}

p.song {
  margin-bottom: 0.5em;
  padding: 0 5px;
}


p.disco {
  color:black;

  font-family: 'Oswald', sans-serif;
  font-size: 1.3em;
  line-height:1;
  margin:0;
  padding:0;

  -webkit-transform: rotateX(25deg) rotateY(20deg) rotateZ(-3deg);
  transform: rotateX(25deg) rotateY(20deg) rotateZ(-3deg);

  -webkit-animation: anim 6s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count:infinite;

  animation: anim 6s;
  animation-timing-function: linear;
  animation-iteration-count:infinite;
}

@-webkit-keyframes anim {
  0%{text-shadow:-6px 4px 0px red;}
  10% {text-shadow:4px -6px 0px green;}
  20% {text-shadow:-9px 4px 0px blue;}
  30% {text-shadow:4px -6px 0px yellow;}
  40% {text-shadow:-8px 4px 0px orange;}
  50% {text-shadow:4px 5px 0px purple;}
  60% {text-shadow:-6px 4px 0px brown;}
  70% {text-shadow:4px 7px 0px pink;}
  80% {text-shadow:-9px -4px 0px lime;}
  90% {text-shadow:4px -6px 0px cyan;}
  100% {text-shadow:-9px 4px 0px teal;}
}

@keyframes anim {
  0%{text-shadow:-6px 4px 0px red;}
  10% {text-shadow:4px -6px 0px green;}
  20% {text-shadow:-9px 4px 0px blue;}
  30% {text-shadow:4px -6px 0px yellow;}
  40% {text-shadow:-8px 4px 0px orange;}
  50% {text-shadow:4px 5px 0px purple;}
  60% {text-shadow:-6px 4px 0px brown;}
  70% {text-shadow:4px 7px 0px pink;}
  80% {text-shadow:-9px -4px 0px lime;}
  90% {text-shadow:4px -6px 0px cyan;}
  100% {text-shadow:-9px 4px 0px teal;}
}

</style>
